<div class="content-container">
  <ng-container *ngIf="isReady$ | async; else loader">
    <select-tenant
      [userName]="userName"
      [tenants]="tenants.tenants"
      (onLogout)="logout()"
      (onToggleSidebar)="toggleSidebar()"
      (onSelectTenant)="selectTenant($event)"
    ></select-tenant>

    <create-tenant-sidebar
      [visible]="sidebarVisible"
      (onHideSidebar)="toggleSidebar()"
      (onCreateTenant)="createTenant($event)"
    ></create-tenant-sidebar>
  </ng-container>

  <ng-template #loader>
    <loader></loader>
  </ng-template>
</div>
