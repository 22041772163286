import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { TranslationPipe } from '@pipes/translation.pipe';

@Component({
  selector: 'page-not-found',
  standalone: true,
  imports: [TranslationPipe],
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent implements OnInit, OnDestroy {
  public readonly componentName = 'PageNotFoundComponent';
  public redirectTimeout: number = 0;

  constructor(private router: Router) {}

  public ngOnInit(): void {
    this.redirectTimeout = window.setTimeout(() => {
      this.router.navigate(['/']);
    }, 2500);
  }

  public ngOnDestroy(): void {
    window.clearTimeout(this.redirectTimeout);
  }
}
