<div class="page-wrapper">
  <div>
    <app-header
      [userName]="userName"
      [userAvatarUrl]="userAvatarUrl"
      [tenantName]="tenantName"
      (onToggleSidebar)="toggleSidebar()"
    ></app-header>
  </div>
  <div class="content-wrapper">
    <breadcrumb></breadcrumb>
    <div class="content">
      <router-outlet></router-outlet>
      <module-navigation-sidebar
        [userName]="userName"
        [userEmail]="userEmail"
        [userAvatarUrl]="userAvatarUrl"
        [companyName]="companyName"
        [visible]="sidebarVisible"
        (onHideSidebar)="toggleSidebar()"
      ></module-navigation-sidebar>
    </div>
    <div class="footer">
      <app-footer></app-footer>
    </div>
  </div>
</div>
