import { Injectable } from '@angular/core';

import { Message } from '@interfaces/alert';
import { RestApiClient } from '@models/rest.api-client';

@Injectable({
  providedIn: 'root',
})
export class TenantsService {
  private restApiClient: RestApiClient;

  constructor() {
    this.restApiClient = new RestApiClient();
  }

  /**
   *  Accept invitation for tenant
   *
   * @param action Action to do on invitation
   * @param token Invitation token
   * @returns Promise of type Message
   */
  invitationAction(action: string, token: string): Promise<Message> {
    return this.restApiClient
      .method('GET')
      .route(`/invitation/${action}/${token}`)
      .withAuthToken()
      .response<Message>();
  }
}
