<p-sidebar
  styleClass="sidebar-wrapper"
  [(visible)]="visible"
  position="right"
  transitionOptions="0.5s"
  (onHide)="hideSidebar()"
>
  <ng-template pTemplate="header">
    <h2>{{ "newTenant" | translation : componentName }}</h2>
  </ng-template>

  <ng-template pTemplate="content">
    <form
      #createTenantForm="ngForm"
      class="form-container"
      (ngSubmit)="createTenant()"
    >
      <div class="form-control-group">
        <div>
          <label for="tenantName">{{
            "tenantName" | translation : componentName
          }}</label>
          <span class="required-field">*</span>
        </div>
        <input
          pInputText
          class="form-input"
          [ngClass]="
            formSubmitted && tenantName.errors ? 'invalid-field' : 'valid-field'
          "
          id="tenantName"
          name="tenantName"
          #tenantName="ngModel"
          [(ngModel)]="tenant.tenantName"
          required
        />
        <small class="validation-error">
          {{ validationError.tenantName[0] }}
        </small>
      </div>

      <div class="form-control-group">
        <div>
          <label for="companyName">{{
            "companyName" | translation : componentName
          }}</label>
          <span class="required-field">*</span>
        </div>
        <input
          pInputText
          class="form-input"
          [ngClass]="
            formSubmitted && companyName.errors
              ? 'invalid-field'
              : 'valid-field'
          "
          id="companyName"
          name="companyName"
          #companyName="ngModel"
          [(ngModel)]="tenant.companyName"
          required
        />
        <small class="validation-error">
          {{ validationError.companyName[0] }}
        </small>
      </div>

      <div class="form-control-group">
        <div>
          <label for="address">{{
            "address" | translation : componentName
          }}</label>
          <span class="required-field">*</span>
        </div>
        <address-search
          name="address"
          #address="ngModel"
          [(ngModel)]="tenant.address"
          [styleClass]="
            formSubmitted && address.errors ? 'invalid-field' : 'valid-field'
          "
          [required]="true"
          (onSelectAddress)="selectAddress($event)"
        ></address-search>
        <small class="validation-error">
          {{ validationError.address[0] }}
        </small>
      </div>

      <div class="form-control-group">
        <div>
          <label for="country">{{
            "country" | translation : componentName
          }}</label>
          <span class="required-field">*</span>
        </div>
        <p-dropdown
          styleClass="{{
            formSubmitted && country.errors
              ? 'form-input invalid-field'
              : 'form-input valid-field'
          }}"
          [options]="countries"
          optionLabel="name"
          optionValue="code"
          id="country"
          name="country"
          #country="ngModel"
          [(ngModel)]="tenant.country"
          scrollHeight="15rem"
          required
          (onChange)="changeSelectedCountry()"
        >
          <ng-template pTemplate="item" let-country>
            <span>{{ country.name | translation : componentName }}</span>
          </ng-template>

          <ng-template pTemplate="selectedItem" let-country>
            <span>{{ country.name | translation : componentName }}</span>
          </ng-template>
        </p-dropdown>
        <small class="validation-error">
          {{ validationError.country[0] }}
        </small>
      </div>

      <div *ngIf="oibVisible" class="form-control-group">
        <div>
          <label for="oib">{{ "oib" | translation : componentName }}</label>
          <span class="required-field">*</span>
        </div>
        <input
          pInputText
          class="form-input"
          [ngClass]="
            formSubmitted && oib.errors ? 'invalid-field' : 'valid-field'
          "
          id="oib"
          name="oib"
          #oib="ngModel"
          [(ngModel)]="tenant.oib"
          minlength="11"
          maxlength="11"
          required
        />
        <small class="validation-error">
          {{ validationError.oib[0] }}
        </small>
      </div>

      <div *ngIf="vatVisible" class="form-control-group">
        <div>
          <label for="vat">{{ "vat" | translation : componentName }}</label>
          <span class="required-field">*</span>
        </div>
        <input
          pInputText
          class="form-input"
          [ngClass]="
            formSubmitted && vat.errors ? 'invalid-field' : 'valid-field'
          "
          id="vat"
          name="vat"
          #vat="ngModel"
          [(ngModel)]="tenant.vat"
          minlength="1"
          maxlength="15"
          required
        />
        <small class="validation-error">
          {{ validationError.vat[0] }}
        </small>
      </div>

      <div *ngIf="jbkjsVisible" class="form-control-group">
        <div>
          <label for="jbkjs">{{ "jbkjs" | translation : componentName }}</label>
          <span class="required-field">*</span>
        </div>
        <input
          pInputText
          class="form-input"
          [ngClass]="
            formSubmitted && jbkjs.errors ? 'invalid-field' : 'valid-field'
          "
          id="jbkjs"
          name="jbkjs"
          #jbkjs="ngModel"
          [(ngModel)]="tenant.jbkjs"
          minlength="1"
          maxlength="15"
          required
        />
        <small class="validation-error">
          {{ validationError.jbkjs[0] }}
        </small>
      </div>

      <button
        pButton
        type="submit"
        class="save-tenant-button"
        label="{{ 'save' | translation : componentName }}"
        [disabled]="waitingForResponse"
      ></button>
    </form>
  </ng-template>
</p-sidebar>
