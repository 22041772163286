import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { ServerError } from '@models/server-error';
import { ErrorHandlerService } from '@services/error-handler.service';
import { MessageService } from '@services/message.service';
import { TenantsService } from '@tenant/tenants.service';

export const invitationGuard: CanActivateFn = async (route, state) => {
  const router = inject(Router);
  const errorHandlerService = inject(ErrorHandlerService);
  const messageService = inject(MessageService);
  const tenantsService = inject(TenantsService);

  const token = route.params['acceptToken'];

  try {
    await tenantsService.invitationAction('accept', token);

    messageService
      .translate('messages.administration')
      .success('invitationAccepted')
      .showMessage();
    router.navigateByUrl('/evidencije');
  } catch (error) {
    errorHandlerService.handleError(new ServerError(error));
  }

  return true;
};
