import { Tenant as TenantInterface } from '@interfaces/tenant';

export class Tenant implements TenantInterface {
  private _id: number;
  private _oib: string;
  private _vat: string;
  private _jbkjs: string;
  private _tenantName: string;
  private _companyName: string;
  private _address: string;
  private _country: string;
  private _active: boolean;
  private _trial: boolean;
  private _createdAt: string;
  private _maxUsers: number;
  private _owner: {
    id: number;
    name: string;
  };

  constructor() {
    this._id = 0;
    this._oib = '';
    this._vat = '';
    this._jbkjs = '';
    this._tenantName = '';
    this._companyName = '';
    this._address = '';
    this._country = '';
    this._active = false;
    this._trial = false;
    this._createdAt = '';
    this._maxUsers = 0;
    this._owner = {
      id: 0,
      name: '',
    };
  }

  public get id(): number {
    return this._id;
  }

  public get oib(): string {
    return this._oib;
  }

  public get vat(): string {
    return this._vat;
  }

  public get jbkjs(): string {
    return this._jbkjs;
  }

  public get tenantName(): string {
    return this._tenantName;
  }

  public get companyName(): string {
    return this._companyName;
  }

  public get address(): string {
    return this._address;
  }

  public get country(): string {
    return this._country;
  }

  public get active(): boolean {
    return this._active;
  }

  public get trial(): boolean {
    return this._trial;
  }

  public get createdAt(): string {
    return this._createdAt;
  }

  public get maxUsers(): number {
    return this._maxUsers;
  }

  public get owner(): {
    id: number;
    name: string;
  } {
    return this._owner;
  }

  public set id(id: number) {
    this._id = id;
  }

  public set oib(oib: string) {
    this._oib = oib;
  }

  public set vat(vat: string) {
    this._vat = vat;
  }

  public set jbkjs(jbkjs: string) {
    this._jbkjs = jbkjs;
  }

  public set tenantName(tenantName: string) {
    this._tenantName = tenantName;
  }

  public set companyName(companyName: string) {
    this._companyName = companyName;
  }

  public set address(address: string) {
    this._address = address;
  }

  public set country(country: string) {
    this._country = country;
  }

  public set active(active: boolean) {
    this._active = active;
  }

  public set trial(trial: boolean) {
    this._trial = trial;
  }

  public set createdAt(createdAt: string) {
    this._createdAt = createdAt;
  }

  public set maxUsers(maxUsers: number) {
    this._maxUsers = maxUsers;
  }

  public set owner(owner: { id: number; name: string }) {
    this._owner = owner;
  }
}
