import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { BreadcrumbComponent } from '@navigation/components/breadcrumb/breadcrumb.component';
import { FooterComponent } from '@navigation/components/footer/footer.component';
import { HeaderComponent } from '@navigation/components/header/header.component';
import { ModuleNavigationSidebarComponent } from '@navigation/components/module-navigation-sidebar/module-navigation-sidebar.component';
import { ActiveTenant } from '@interfaces/tenant';
import { User } from '@interfaces/authentication';
import { SecureLocalStorage } from '@models/secure-local-storage';

@Component({
  selector: 'navigation',
  standalone: true,
  imports: [
    RouterOutlet,
    BreadcrumbComponent,
    FooterComponent,
    HeaderComponent,
    ModuleNavigationSidebarComponent,
  ],
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
  public userName: string = '';
  public userEmail: string = '';
  public userAvatarUrl: string = '';
  public tenantName: string = '';
  public companyName: string = '';
  public sidebarVisible: boolean = false;
  private secureLocalStorage: SecureLocalStorage;

  constructor() {
    this.secureLocalStorage = new SecureLocalStorage();
  }

  public ngOnInit(): void {
    this.getUserFromStorage();
    this.getTenantFromStorage();
  }

  /**
   * Retrieves the user information from the storage service and assigns it to
   * the component properties.
   * This method is called during the component initialization to populate the
   * user-related data.
   */
  private getUserFromStorage(): void {
    if (!this.secureLocalStorage.has('user')) {
      return;
    }

    const user: User = this.secureLocalStorage.get('user').toJSON().value;
    this.userName = user.firstName + ' ' + user.lastName;
    this.userEmail = user.email;
    this.userAvatarUrl = user.profilePhoto;
  }

  /**
   * Retrieves the active tenant information from the storage service and assigns
   * it to the component properties.
   * This method is called during the component initialization to populate the
   * tenant-related data.
   */
  private getTenantFromStorage(): void {
    if (!this.secureLocalStorage.has('activeTenant')) {
      return;
    }

    const activeTenant: ActiveTenant = this.secureLocalStorage
      .get('activeTenant')
      .decrypt()
      .toJSON().value;
    this.tenantName = activeTenant.tenant.tenantName ?? '';
    this.companyName = activeTenant.tenant.companyName ?? '';
  }

  /**
   * Toggles the visibility of the sidebar.
   */
  public toggleSidebar(): void {
    this.sidebarVisible = !this.sidebarVisible;
  }
}
