import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgIf } from '@angular/common';

import { ButtonModule } from 'primeng/button';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';

import { slideFromTop } from '@animations/animation';
import { TranslationPipe } from '@pipes/translation.pipe';
import { Tenant } from '@interfaces/tenant';

@Component({
  selector: 'select-tenant',
  standalone: true,
  imports: [
    NgIf,
    TranslationPipe,
    ButtonModule,
    IconFieldModule,
    InputIconModule,
    InputTextModule,
    TableModule,
  ],
  templateUrl: './select-tenant.component.html',
  styleUrls: ['./select-tenant.component.scss'],
  animations: [slideFromTop],
})
export class SelectTenantComponent {
  public readonly componentName: string = 'SelectTenantComponent';
  @Input() userName: string = '';
  @Input() tenants: Array<Partial<Tenant>> = [];
  @Output() onLogout: EventEmitter<void> = new EventEmitter<void>();
  @Output() onToggleSidebar: EventEmitter<void> = new EventEmitter<void>();
  @Output() onSelectTenant: EventEmitter<Tenant> = new EventEmitter<Tenant>();

  /**
   * Emits an event to notify that the user has logged out.
   */
  public logout(): void {
    this.onLogout.emit();
  }

  /**
   * Emits an event to notify that the sidebar should be toggled.
   */
  public toggleSidebar(): void {
    this.onToggleSidebar.emit();
  }

  /**
   * Emits an event to notify that a tenant has been selected.
   * @param tenant - The tenant that was selected.
   */
  public selectTenant(tenant: Tenant): void {
    if (!tenant.active) {
      return;
    }

    this.onSelectTenant.emit(tenant);
  }
}
