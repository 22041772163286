import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { FormsModule, NgForm } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

import { z, ZodError } from 'zod';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';

import { TranslationPipe } from '@pipes/translation.pipe';
import { PasswordStrengthDirective } from '@directives/password-strength.directive';
import { ServerError } from '@models/server-error';
import { User } from '@interfaces/authentication';
import { ErrorHandlerService } from '@services/error-handler.service';
import { RestApiClient } from '@models/rest.api-client';
import { MessageService } from '@services/message.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    FormsModule,
    AsyncPipe,
    TranslationPipe,
    PasswordStrengthDirective,
    ButtonModule,
    DividerModule,
    FloatLabelModule,
    InputTextModule,
    PasswordModule,
  ],
})
export class NewPasswordComponent implements OnInit, OnDestroy {
  public readonly componentName = 'NewPasswordComponent';
  @ViewChild('passwordForm', { static: false }) public passwordForm!: NgForm;
  public isReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public user: Partial<User> = { password: '' };
  public token: string = '';
  public strongRegex: RegExp =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  public formSubmitted: boolean = false;
  public waitingForResponse: boolean = false;
  public validationError: {
    password: Array<string>;
  } = { password: [] };
  public timeout: number = 0;
  private restApiClient: RestApiClient;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private errorHandlerService: ErrorHandlerService,
    private messageService: MessageService
  ) {
    this.restApiClient = new RestApiClient();
  }

  public ngOnInit(): void {
    this.timeout = window.setTimeout(() => {
      this.isReady$.next(true);
    }, 500);

    this.token =
      this.activatedRoute.snapshot.paramMap.get('resetToken') ?? this.token;
  }

  /**
   * Validates the user's password input against a strong password regex pattern.
   * If the password is valid, it returns true. Otherwise, it populates the
   * `validationError` object with the error messages.
   *
   * @returns {boolean} True if the password is valid, false otherwise.
   */
  private validation(): boolean {
    const FormData = z
      .object({
        password: z
          .string()
          .min(1, 'passwordRequiredField')
          .regex(this.strongRegex, 'passwordStructureInvalid'),
      })
      .strict();

    try {
      FormData.parse(this.user);

      return true;
    } catch (error) {
      if (error instanceof ZodError) {
        const fieldErrors = error.flatten().fieldErrors;

        this.validationError = {
          password: fieldErrors['password'] ?? [],
        };
      }
    }

    return false;
  }

  /**
   * Attempts to reset the user's password by sending a request to the server
   * with the provided token and new password.
   *
   * If the form is invalid, it displays an error message. Otherwise, it sends
   * a POST request to the '/password/reset' endpoint with the token and new password.
   * If the request is successful, it navigates the user to the login page.
   * If there is an error, it handles the error using the `errorHandlerService`.
   */
  public async resetPassword(): Promise<void> {
    this.formSubmitted = true;

    if (!this.validation()) {
      return;
    }

    this.waitingForResponse = true;

    try {
      await this.restApiClient
        .method('POST')
        .route('/password/reset')
        .body({ token: this.token, password: this.user.password })
        .response<any>();

      this.messageService
        .translate(this.componentName)
        .error('passwordSuccessfullyChanged')
        .showMessage();

      this.router.navigate(['/prijava']);
    } catch (error) {
      this.errorHandlerService.handleError(new ServerError(error));
    }

    this.waitingForResponse = false;
  }

  public ngOnDestroy(): void {
    window.clearTimeout(this.timeout);
  }
}
