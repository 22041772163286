import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { FormsModule, NgForm } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { z, ZodError } from 'zod';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';

import { TranslationPipe } from '@pipes/translation.pipe';
import { PasswordStrengthDirective } from '@directives/password-strength.directive';
import { ServerError } from '@models/server-error';
import { Authentication, User } from '@interfaces/authentication';
import { ErrorHandlerService } from '@services/error-handler.service';
import { RestApiClient } from '@models/rest.api-client';
import { MessageService } from '@services/message.service';
import { SecureLocalStorage } from '@models/secure-local-storage';

@Component({
  selector: 'signup',
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    FormsModule,
    RouterLink,
    AsyncPipe,
    TranslationPipe,
    PasswordStrengthDirective,
    ButtonModule,
    DividerModule,
    FloatLabelModule,
    InputTextModule,
    PasswordModule,
  ],
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit, OnDestroy {
  public readonly componentName = 'SignupComponent';
  @ViewChild('signupForm', { static: false }) public signupForm!: NgForm;
  public isReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public user: Partial<User> = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  };
  public strongRegex: RegExp =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_])[A-Za-z\d@$!%*?&_]{8,}$/;
  public formSubmitted: boolean = false;
  public waitingForResponse: boolean = false;
  public validationError: {
    firstName: Array<string>;
    lastName: Array<string>;
    email: Array<string>;
    password: Array<string>;
  } = { firstName: [], lastName: [], email: [], password: [] };
  public timeout: number = 0;
  private restApiClient: RestApiClient;
  private secureLocalStorage: SecureLocalStorage;

  constructor(
    private router: Router,
    private errorHandlerService: ErrorHandlerService,
    private messageService: MessageService
  ) {
    this.restApiClient = new RestApiClient();
    this.secureLocalStorage = new SecureLocalStorage();
  }

  public ngOnInit(): void {
    this.timeout = window.setTimeout(() => {
      this.isReady$.next(true);
    }, 500);
  }

  /**
   * Validates the user input for the signup form.
   *
   * This method uses the Zod library to define a schema for the user object,
   * which includes fields for firstName, lastName, email, and password.
   * It then attempts to parse the user object against the schema, and if
   * successful, returns true. If there are any validation errors, it
   * populates the `validationError` object with the error messages for
   * each field, and returns false.
   *
   * @returns {boolean} True if the user input is valid, false otherwise.
   */
  private validation(): boolean {
    const FormData = z
      .object({
        firstName: z.string().min(1, 'firstNameRequiredField'),
        lastName: z.string().min(1, 'lastNameRequiredField'),
        email: z
          .string()
          .min(1, 'emailRequiredField')
          .email('emailStructureInvalid'),
        password: z
          .string()
          .min(1, 'passwordRequiredField')
          .regex(this.strongRegex, 'passwordStructureInvalid'),
      })
      .strict();

    try {
      FormData.parse(this.user);

      return true;
    } catch (error) {
      if (error instanceof ZodError) {
        const fieldErrors = error.flatten().fieldErrors;

        this.validationError = {
          firstName: fieldErrors['firstName'] ?? [],
          lastName: fieldErrors['lastName'] ?? [],
          email: fieldErrors['email'] ?? [],
          password: fieldErrors['password'] ?? [],
        };
      }
    }

    return false;
  }

  /**
   * Handles the signup process for the application.
   *
   * This method is responsible for validating the user input, making a POST
   * request to the server to register the user, and storing the authentication
   * token and user data in the local storage.
   * If the signup is successful, it navigates the user to the 'evidencije' route.
   * If there is an error, it handles the error using the `ErrorHandlerService`.
   */
  public async signup(): Promise<void> {
    this.formSubmitted = true;

    if (!this.validation()) {
      return;
    }

    this.waitingForResponse = true;

    try {
      const authentication = await this.restApiClient
        .method('POST')
        .route('/register')
        .body(this.user)
        .onError('noNavigation')
        .response<Authentication>();

      this.secureLocalStorage
        .setValue(authentication.authToken)
        .toString()
        .encrypt()
        .store('authToken');
      this.secureLocalStorage
        .setValue(authentication.user)
        .toString()
        .store('user');

      this.messageService
        .translate(this.componentName)
        .success('signupSuccessful')
        .showMessage();

      this.router.navigateByUrl('evidencije');
    } catch (error) {
      this.errorHandlerService.handleError(new ServerError(error));
    }

    this.waitingForResponse = false;
  }

  public ngOnDestroy(): void {
    window.clearTimeout(this.timeout);
  }
}
