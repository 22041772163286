import { Component, OnInit } from '@angular/core';
import { AsyncPipe, NgIf } from '@angular/common';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { LoaderComponent } from '@components/loaders/loader/loader.component';
import { CreateTenantSidebarComponent } from '@tenant/components/create-tenant-sidebar/create-tenant-sidebar.component';
import { SelectTenantComponent } from '@tenant/components/select-tenant/select-tenant.component';
import { RestApiClient } from '@models/rest.api-client';
import { SecureLocalStorage } from '@models/secure-local-storage';
import { ServerError } from '@models/server-error';
import { Tenant } from '@models/tenant';
import {
  ActiveTenant,
  Tenant as TenantInterface,
  Tenants,
} from '@interfaces/tenant';
import { User } from '@interfaces/authentication';
import { ErrorHandlerService } from '@services/error-handler.service';

@Component({
  selector: 'tenant',
  standalone: true,
  imports: [
    NgIf,
    AsyncPipe,
    LoaderComponent,
    CreateTenantSidebarComponent,
    SelectTenantComponent,
  ],
  templateUrl: './tenant.component.html',
  styleUrls: ['./tenant.component.scss'],
})
export class TenantComponent implements OnInit {
  public isReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public userName: string = '';
  public tenants!: Tenants;
  public sidebarVisible: boolean = false;
  private restApiClient: RestApiClient;
  private secureLocalStorage: SecureLocalStorage;

  constructor(
    private router: Router,
    private errorHandlerService: ErrorHandlerService
  ) {
    this.restApiClient = new RestApiClient();
    this.secureLocalStorage = new SecureLocalStorage();
  }

  public ngOnInit(): void {
    this.getUserFromStorage();
    this.tenant();
  }

  /**
   * Retrieves the user information from the secure local storage and sets the
   * userName property.
   * This method is called during the component initialization to populate the
   * user's name.
   */
  private getUserFromStorage(): void {
    if (!this.secureLocalStorage.has('user')) {
      return;
    }

    const user: User = this.secureLocalStorage.get('user').toJSON().value;
    this.userName = user.firstName + ' ' + user.lastName;
  }

  /**
   * Retrieves the list of tenants from the server and stores them in the
   * `tenants` property.
   * If the API call is successful, it sets the `isReady$` subject to `true`
   * to indicate that the component is ready to be displayed.
   * If an error occurs during the API call, it delegates the error handling
   * to the `errorHandlerService`.
   */
  private async tenant(): Promise<void> {
    try {
      this.tenants = await this.restApiClient
        .method('GET')
        .route('/tenant')
        .withAuthToken()
        .response<Tenants>();
    } catch (error) {
      this.errorHandlerService.handleError(new ServerError(error));
    }

    this.isReady$.next(true);
  }

  /**
   * Logs the user out of the application and navigates to the 'odjava' route.
   */
  public logout(): void {
    this.router.navigateByUrl('odjava');
  }

  /**
   * Toggles the visibility of the sidebar.
   * This method is used to show or hide the sidebar component based on the
   * current state of the `sidebarVisible` property.
   */
  public toggleSidebar(): void {
    this.sidebarVisible = !this.sidebarVisible;
  }

  /**
   * Selects the specified tenant and stores the active tenant and dashboard
   * configuration in the secure local storage.
   * After the tenant is selected, the user is navigated to the root route.
   *
   * @param tenant - The tenant to be selected.
   * @returns A Promise that resolves when the tenant selection is complete.
   */
  public async selectTenant(tenant: TenantInterface): Promise<void> {
    try {
      const activeTenant = await this.restApiClient
        .method('POST')
        .route('/tenant/select')
        .body({ id: tenant.id })
        .withAuthToken()
        .response<ActiveTenant>();

      this.secureLocalStorage
        .setValue(activeTenant)
        .toString()
        .encrypt()
        .store('activeTenant');

      this.secureLocalStorage
        .setValue(activeTenant.memberPreferences.dashboardLayout)
        .store('dashboardConfiguration');

      this.router.navigate(['/']);
    } catch (error) {
      this.errorHandlerService.handleError(new ServerError(error));
    }
  }

  /**
   * Creates a new tenant in the system.
   *
   * @param tenant - The tenant object to be created.
   * @returns A Promise that resolves when the tenant creation is complete.
   */
  public async createTenant(tenant: Tenant): Promise<void> {
    try {
      const newTenant = await this.restApiClient
        .method('POST')
        .route('/tenant/store')
        .body(tenant)
        .withAuthToken()
        .response<TenantInterface>();

      this.tenants = {
        ...this.tenants,
        tenants: [...this.tenants.tenants, newTenant],
      };

      this.toggleSidebar();
    } catch (error) {
      this.errorHandlerService.handleError(new ServerError(error));
    }
  }
}
